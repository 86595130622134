import { NotFoundError, useCrash } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useSearch } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { useSetEmbeddingPartner } from '../../../lib/embedding-partner'
import { useStartSelfOnboarding } from '../../../lib/hooks/use-start-self-onboarding'
import { getSubscriptionInjectableParamsFromURLSearchParams } from '../../../lib/subscription-injectable-params'
import { GlobalLoadingState } from '../../molecules/global-loading-state'

const SubscriptionV2LandingPage = memo(function ActivitySearchRestaurantPage() {
  const startSelfOnboarding = useStartSelfOnboarding()
  const setEmbeddingPartner = useSetEmbeddingPartner()
  const crash = useCrash()
  const searchParams = useSearch({ strict: false })

  useEffect(() => {
    const startSelfOnboardingParamsResult = getSubscriptionInjectableParamsFromURLSearchParams(searchParams)
    if (isFailure(startSelfOnboardingParamsResult)) {
      switch (startSelfOnboardingParamsResult.problem.type) {
        case 'invalid-params':
          crash(new NotFoundError())
          return
      }
    }
    const startSelfOnboardingParams = startSelfOnboardingParamsResult.output

    startSelfOnboarding(startSelfOnboardingParams)
  }, [crash, searchParams, setEmbeddingPartner, startSelfOnboarding])

  return <GlobalLoadingState />
})

export default SubscriptionV2LandingPage
